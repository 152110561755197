@font-face {
  font-family: 'Ringbearer';
  src: url('RingbearerMedium.woff2') format('woff2'),
      url('RingbearerMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aniron';
  src: url('Aniron-Bold.woff2') format('woff2'),
      url('Aniron-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


h1 {
  text-align: center;
  font-family: 'Ringbearer';
  font-weight: 500;
  font-style: normal;
  font-size: 4em;
  margin: 0;
  text-align: center;
  color: #fff;
  margin-bottom: 2rem;
}

.App {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.App-bg {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blockquote {
  width: 60rem;
  max-width: 100%;
}

blockquote {
  font-family: 'Aniron';
  font-style: normal;
  border-left: 3px solid #fff;
  padding-left:2rem;
  quotes: "\201C""\201D""\2018""\2019";
  color: #fff;
  /* margin-bottom: 2rem; */
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* blockquote:before {
  color: #fff;
  content: '"';
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
} */

blockquote p {
  display: inline;
}

blockquote footer {
  text-align: right;
}

.ring {
  height: 5rem;
  pointer-events: none;
  /* margin-bottom: 2rem; */
}

.ring {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.footer p {
  font-family: 'Aniron';
  font-style: normal;
  font-size: .75em;
  text-align: center;
}

p, a {
  color: #fff;
}

@media (prefers-reduced-motion: no-preference) {
  .ring {
    animation: ring-spin infinite 20s linear;
  }
}

@keyframes ring-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 458px) {
  h1 {
    font-size: 3em;
  }
  blockquote {
    height: 15rem;
  }
  .blockquote {
    font-size: .9em;
  }
  .ring {
    height: 2.5rem;
  }
}